import React from 'react'
// import flechaDerecha from '../../../static/assets/images/ui/calorex_flecha_derecha.png'
// import flechaIzquierda from '../../../static/assets/images/ui/calorex_flecha_izquierda.png'

export const SliderSlickButton = ({ _className, onClick, isNext=false }) =>{
    return (
        <div className={_className} onClick={onClick}>
            {
                isNext ? <img src="/assets/images/ui/calorex_flecha_derecha.svg" alt="calorex_flecha_derecha"/> : <img src="/assets/images/ui/calorex_flecha_izquierda.svg" alt="calorex_flecha_izquierda"/>
            }
        </div>
    )
}