import { useStaticQuery, graphql } from "gatsby"
export const usePrismDealsPage = () => {
const prismic = useStaticQuery(
    graphql`
        query{
            allPrismicSpecialDealsPage {
                edges {
                  node {
                    data {
                      title {
                        text
                      }
                      campaign_name {
                        text
                      }
                      main_image {
                        url
                        lg {
                          url
                        }
                        md {
                          url
                        }
                        xs {
                          url
                        }
                      }
                      body {
                        ... on PrismicSpecialDealsPageBodyStorytelling2 {
                          id
                          primary {
                            storytelling_title {
                              text
                              html
                            }
                            storytelling_2_description {
                              text
                              html
                            }
                          }
                        }
                      }
                      body1 {
                        primary {
                          dealer_name {
                            text
                          }
                          dealer_image {
                            url
                            alt
                          }
                          display_name
                          dealer_short_name {
                            text
                          }
                        }
                        items {
                          location {
                            document {
                              ... on PrismicLocation {
                                data {
                                  coordinates {
                                    latitude
                                    longitude
                                  }
                                  phone
                                  state
                                  store
                                  website
                                  address
                                  branchoffice
                                  document_id
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
        }
    `
  );
//  console.log('promotions->',prismic.allPrismicSpecialDealsPage);
return prismic.allPrismicSpecialDealsPage

  
}
