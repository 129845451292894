import React, { useState, useEffect } from 'react'
import Dropdown from '../../components/_comparator/dropdown';
import Store from '../../components/_promotions/promotions_stores';
import { pushToDataLayer } from '../../components/gtm/pushDataLayer'

const Offers = ({_stores, _campaign_name}) =>{

    const [ defaultItem, setDefaultItem ] = useState(null) /* eslint-disable */
    const [states, setState] = useState(null)
    const [stores, setStores] = useState(null)
    const [storesByState, setStoresByState] = useState([])
    const [selectState, setSelecState ] = useState(false)
    const [numnberItem, setNumberItem ] = useState(false)
    const [initialItems, setInitialItem] = useState(null)

    
    const handleState = (e) =>{
        setDefaultItem(e.data.title.text)
        setSelecState(true)

        let storesState = []

        _stores.map(item =>{
           item.items.map(resp =>{
               if(resp.location !== null){
                resp.location.document.map(result =>{
                    if(result.data  !== undefined&& result.data !== null){
                        if(result.data.state.toUpperCase() === e.data.title.text){
                            storesState = [
                                ...storesState,
                            {
                                data: {
                                    dealer: item.primary,
                                    store: result
                                }
                            }
                            ]
                        }
                    }
                })
               }
           })
        })

        let arrayImages = []
        let result = []

        storesState.map(item => {
            arrayImages.push(item.data.dealer)
            result = arrayImages.filter((item, index) => {
              return arrayImages.indexOf(item) === index
          })
        })
        
        setNumberItem(result.length)
        setStoresByState(storesState)

        const gtmStateName = e.data.title.text
        pushToDataLayer({
            event: "deals_selected_state",
            campaignName: _campaign_name.text,
            stateName: gtmStateName,
            eventCallback: () => {},
        })
    }

    useEffect(() =>{
        
        let state = []
        let statesUnicos = []
        let stateOrder= []
        let newArrayState = []
        let stores = []

        _stores.map((item) =>{
           if(item.items[0].location === null) return
            stores = [
                ...stores, {
                    dealer_image: item.primary.dealer_image,
                    dealer_name: item.primary.dealer_name.text  
                }
            ]
            setStores(stores)
            return item.items.map((resp) =>{
                if(resp.location !== null){
                    return resp.location.document.map((values) =>{
                        if(values.data  !== undefined&& values.data !== null){
                            state.push(values.data.state.toUpperCase())  
                            
                            return statesUnicos = state.filter((item, index) => {
                                    return state.indexOf(item) === index;
                            });

                        }
                   })
                  
                }
            })
        })

        stateOrder = statesUnicos.sort()
        stateOrder.map(item =>{
           return newArrayState = [
                ...newArrayState,
                { data: { title: {"text": item}}},
            ]
       })

       setDefaultItem(newArrayState[0].data.title.text)
        setState(newArrayState)       

///// numero de items del primer estado

        let initialDealer = []

        _stores.map(item =>{
           item.items.map(resp =>{
               if(resp.location !== null){
                resp.location.document.map(result =>{
                    if(result.data  !== undefined && result.data !== null){
                        if(result.data.state.toUpperCase() === newArrayState[0].data.title.text){
                            initialDealer = [
                                ...initialDealer,
                               {
                                   data: {
                                       dealer: item.primary,
                                       store: result
                                   }
                               }
                            ]
                        }
                    }
                })
               }
           })
        })

        let arrayInitial = []
        let resultado = []

        initialDealer.map(resp => {
            arrayInitial.push(resp.data.dealer)
            resultado = arrayInitial.filter((item, index) => {
              return arrayInitial.indexOf(item) === index
          })
        })

        setInitialItem(resultado)
      
    // eslint-disable-next-line
    }, [])

    
    return(
        <div className="container_offers">
            <div className="container_offers--title">
                <p> Selecciona un Estado</p>
            </div>
            <div className="container_offers--states">
                <Dropdown items={states} default_item={ defaultItem !== null ? defaultItem :'Selecciona un estado'} handleItemClick={handleState} type={2} /> 
            </div>
                { 
                    stores !== null && 
                    <Store  _defaultItem={defaultItem} _currentStores={storesByState} _selectState={selectState} _allDealerList={_stores} _initialItems={initialItems} _numberItems={numnberItem} _campaign_name={_campaign_name} />
                }
        </div>
   )
}

export default Offers