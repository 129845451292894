import React, { useState, useEffect, useRef } from 'react'

import Slider from "react-slick"
import SliderArrow from "../sliders/slider_arrow"
import { SliderSlickButton } from "../buttons/_button_slider_slick"
import { Picture } from "react-responsive-picture"
import { useCurrenDeviceMaintenance } from "../../hooks/useCurrentDeviceMaintenance"
import { WrappedMapLg } from './dealerMap';
import StoresList from  "./storesList"

import { pushToDataLayer } from '../gtm/pushDataLayer'

const Store = ({_defaultItem, _currentStores, _selectState, _allDealerList, _initialItems, _numberItems, _campaign_name}) =>{
/* eslint-disable */
  const [winWidth, setWinWidth] = useState(null)
  let sliderArrow = new SliderArrow()
  const bannerSectionRef = useRef(null)
  const sliderYoutube = useRef(null)
  const [device] = useCurrenDeviceMaintenance()
  const [numberImage, setNumberImage] = useState(null)
  const [initialStores, setInitialStores] = useState()
  const [listStores, setListStores] = useState()
  const [currentListStores, setCurrentListStores] = useState(null)
  const [currentStore, setCurrentStore] = useState(null)
  const [isCurrentImage, setIsCurrentImage] = useState(false)
  const [locations, setLocations] = useState(null)

  const API = 'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCrvecNkmpCUHkEpD-EhbEJR3HIGiHcXKY';
  const [currentPosition, setCurrentPosition] = useState({latitude: 23.6345005, longitude: -102.5527878, zoom: 5.5});

  
  const settings = {
    customPaging: function(i) {
      return <span className="pagination__bullet"></span>
    },
    dots: true,
    dotsClass: "pagination__dots offers_dots",
    lazyLoad: true,
    infinite: false,
    speed: 500,
    slidesToShow: winWidth,
    slidesToScroll: 1,
    prevArrow: <SliderSlickButton _className="offers-button-prev" />,
    nextArrow: (
      <SliderSlickButton _className="offers-button-next" isNext={true} />
    ),
  }

  useEffect(() => {  
    let initialStore =[]

    _allDealerList.map(item =>{
      item.items.map(resp =>{
        if(resp.location === null) return
        resp.location.document.map(dealer =>{
          if(dealer.data !== undefined && dealer.data !== null)
          {
            if( dealer.data.state.toUpperCase() === _defaultItem){
              initialStore = [
                ...initialStore,
                {
                  primary:{
                    dealer_name: _initialItems[0].dealer_name,
                    dealer_image: _initialItems[0].dealer_image
                  },
                  items: dealer
                }
              ]
            }
          }
        })
       })
    })
    setInitialStores(initialStore[0])
  }, [])

  useEffect(() => {
    if(_currentStores.length > 0 ){
        let result = []
         _currentStores.map((store, index) =>{
             if(index === 0){
                 result = [store.data.store]
                 setCurrentStore( result[0].data.coordinates)
             }

         })
     }
}, [_currentStores ])

  useEffect(() => {
    if (!bannerSectionRef.current) return
    sliderArrow.init(bannerSectionRef)
  }, [sliderArrow])

  useEffect(() => {
    if(_selectState){
      setNumberImage(_numberItems)
    }else{
      setNumberImage(_initialItems.length)
    }

  }, [_selectState, _numberItems, _initialItems])

   useEffect(() =>{
   
    if(device === "mobile"){
        setWinWidth(1)
    }
    if(device === "tablet"){
      if(numberImage > 1){
        setWinWidth(2)
      }else{
        setWinWidth(1)
      }
      
    }
    if(device === "desktopMin"){
      if(numberImage > 2 ){
        setWinWidth(3)
        document.querySelector(".detail_offers").style.width = "920px";
        return () => {
          document.querySelector(".detail_offers").style.width = "90%"
        }
      }else{
        setWinWidth(numberImage)
        document.querySelector(".detail_offers").style.width = "600px";
        return () => {
          document.querySelector(".detail_offers").style.width = "680px"
        }
      }
  }
    if(device === "desktop"){
      if(numberImage > 2 &&  numberImage < 4){
        setWinWidth(3)
        document.querySelector(".detail_offers").style.width = "830px";
        return () => {
          document.querySelector(".detail_offers").style.width = "90%"
        }
      }
     else if(numberImage >= 4 ){
        setWinWidth(4)
        document.querySelector(".detail_offers").style.width = "90%";
        return () => {
          document.querySelector(".detail_offers").style.width = "95%"
        }
      }else{
        setWinWidth(numberImage)
        document.querySelector(".detail_offers").style.width = "600px";
        return () => {
          document.querySelector(".detail_offers").style.width = "90%"
        }
      }
    }
    if(device === "xlg"){
      if(numberImage < 3){
        setWinWidth(numberImage)
        document.querySelector(".detail_offers").style.width = "600px";
        return () => {
          document.querySelector(".detail_offers").style.width = "90%"
        }  
      }
      else if(numberImage < 5){
        setWinWidth(numberImage)
        document.querySelector(".detail_offers").style.width = "1100px";
        return () => {
          document.querySelector(".detail_offers").style.width = "90%"
        }
      }
      else{
        setWinWidth(5)
        document.querySelector(".detail_offers").style.width = "95%"
        return () => {
          document.querySelector(".detail_offers").style.width = "90%"
        }
       
      }
   }

  },[device, numberImage]) 


const handleStore = (dealer)=> {

  const gtmDealerName = dealer
  const stateName = _defaultItem
  pushToDataLayer({
   event: "deals_selected_dealer",
   campaignName: _campaign_name.text,
   dealerName: gtmDealerName,
   stateName: stateName,
   eventCallback: () => {},
 })
 
  setIsCurrentImage(true)
    if(_selectState){
      let newArray = []
      _currentStores.map(resp =>{
        if( resp.data.dealer.dealer_name.text === dealer){
          newArray = [...newArray, {data: resp.data}]
           setCurrentListStores(newArray)
        }
      })
    }
    else{
      return _allDealerList.map(allStore => {
        allStore.items.map(item =>{
          if(item.location === null ) return

           if(allStore.primary.dealer_name.text === dealer){
              setListStores(allStore)

           
           }     
        })


      })
    }

    
  }
  const showItems = (result) =>{
     return result.map((resp, index) => {
         return (
           <div key={index} className={`container_image--dealer`} 
            onClick={() =>{handleStore(resp.dealer_name.text)}}>
             <Picture
                   className=""
                   src={resp.dealer_image.url}
                   alt=""
              />
              <p>{resp.display_name ? resp.dealer_short_name.text : null}</p>
           </div>
          )
      })
  }

  function get_items(items){  
  let arrayImages = []
  let result = []
    
    items.map((item) => {
        arrayImages.push(item.data.dealer)
          result = arrayImages.filter((item, index) => {
            return arrayImages.indexOf(item) === index
        })
     }) 
     
    return showItems(result)
}

  function create_items(_items) {     
     return _items.map((_item, _index) => {  
       return (
        <div key={_index} className={`container_image--dealer`} 
         onClick={() =>{handleStore(_item.dealer_name.text)}}>
          <Picture
                className=""
                src={_item.dealer_image.url}
                alt=""
            />
            <p>{_item.display_name ? _item.dealer_short_name.text : null}</p>
        </div>
       )
    })
  }

  return(
      <>
        <div className="detail_offers" ref={bannerSectionRef} >
            <Slider ref={sliderYoutube} {...settings}>
              { _selectState ?  get_items(_currentStores ) : create_items(_initialItems ) }
            </Slider>
        </div> 
        <div className="detail_stores--map">
         {
            initialStores && <StoresList _initialStores={initialStores} _currentStores={_currentStores} _currentListStores={currentListStores} _listStores={listStores} setCurrentStore={setCurrentStore} _isCurrentImage={isCurrentImage} _campaign_name={_campaign_name}/>
         }
        <div className="c-dealers__map c_map--offers">
             <WrappedMapLg
                location={currentStore} 
                locationsStores={locations}
                defaultLocation={currentPosition}
                googleMapURL={API}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
            />
        </div>
      </div>
      </>
  )

}

export default Store