import React, { useEffect, useRef } from 'react';
import { GoogleMap, withScriptjs, withGoogleMap, Marker } from 'react-google-maps';
import mapStyle from '../../../static/assets/data/dealers/styleMap.json'; 
import pinOver from '../../../static/assets/images/dealers/pin_over.png';

function Map({location, ref}) {

    return(
        <GoogleMap
            ref={ref}
            defaultZoom={10}
            defaultCenter={{lat: location.data.coordinates.latitude, lng: location.data.coordinates.longitude}}
            defaultOptions={{styles: mapStyle}}
            zoom={location ? 17 : 10}
        >
            
            <Marker icon={pinOver} key={2} position={{lat: location.data.coordinates.latitude, lng: location.data.coordinates.longitude}} />
        </GoogleMap>
    )
}

function MapLg({locationsStores, location, defaultLocation, overStore, overBranchoffice}) {
    const refMap = useRef(null)
    // function fitStateBounds(locationStore){        
        
    //     if(locationsStores.length!==0) {
    //         let bounds;
    //         bounds = new window.google.maps.LatLngBounds();
    //         locationsStores.map((_location)=>{
    //             if(_location!==null) {
    //                return bounds.extend(new window.google.maps.LatLng(
    //                     _location.data.coordinates.latitude,
    //                     _location.data.coordinates.longitude
    //             ))  
    //             } else return null
                
    //         })
    //         // handleBound(bounds)
    //         // const boundsDelGet = refMap.getBounds()
    //         refMap.current.fitBounds(bounds)
    //         // refMap.current.center = bounds.getCenter();
    //     }       
        
    // }
    
    useEffect(()=>{
        //console.log('cambia locations',locations)
      //  fitStateBounds(locationsStores)
    },[locationsStores])
    
    // function handleOverStore(locationItem) {
    //     if(overStore.toLowerCase() === locationItem.data.store.toLowerCase() ) {
    //         return pinOver
    //     }
    //     return pin
    // }

    // function handleOverBranchOffice(locationItem) {
    //     if(overBranchoffice.uid === locationItem.uid) {
    //         return pinOver
    //     }
    //     return pin
    // }
    return(
        <GoogleMap
            ref={refMap}
            defaultZoom={5.5}
            defaultCenter={{
                lat: defaultLocation.latitude, 
                lng: defaultLocation.longitude
            }}
            center= {{                
                lat: location === null ? defaultLocation.latitude : location.latitude,
                lng: location === null ? defaultLocation.longitude : location.longitude
            }} 
            
            
            zoom={ location !== null? 15 : defaultLocation.zoom}
            defaultOptions={{styles: mapStyle}}
        >
            {
                location !== null ?
                    <Marker
                        icon={pinOver}
                     //   onMouseOver={setIconMouseOver}
                        position={{lat: location.latitude, lng: location.longitude}}
                    />
                :
                null
                // locationsStores.map((locationItem, i) => {
                //         return <Marker
                //                     key={i + "-location"}
                //                     icon={ pin }
                //                     // icon={overStore ? handleOverStore(locationItem) :  overBranchoffice ? handleOverBranchOffice(locationItem) : pin }
                //                   //  onMouseOver={setIconMouseOver}
                //                     position={{lat: locationItem.data.coordinates.latitude, lng: locationItem.data.coordinates.longitude}}
                //                 />
                //     })
               
            }
            
        </GoogleMap>
       
    )
}


export const WrappedMap = withScriptjs(withGoogleMap(Map));
export const WrappedMapLg = withScriptjs(withGoogleMap(MapLg));
