import React from 'react'
import { Picture } from "react-responsive-picture"

const Header = ({ _image }) => {
    // const title = "¡Aprovecha los increibles descuentos!"
    // const imageXs = _image.xs.url
    const imagetablet = _image.md.url
    const image1024 = _image.lg.url
    const imagelg = _image.url

    return (
        <div className="promotions_container--header">
            <div className="promotions_container--image">
                <Picture
                    className="imagen_header"
                    sources={[
                        {
                            srcSet: imagetablet,
                            media: "(max-width: 767px)",
                        },
                        {
                            srcSet: imagetablet,
                            media: "(max-width: 1023px)",
                        },
                        {
                            srcSet: image1024,
                            media: "(max-width: 1359px)",
                        },
                        {
                            srcSet: imagelg,
                            media: "(min-width: 1360px)",
                        }
                    ]}
                />
            </div>
            {/* <div className="promotions_container--title">
              <p>{title}</p>
            </div> */}
        </div>
    )
}

export default Header
