import React, {useState, useEffect} from "react";
import { Picture } from "react-responsive-picture"
import PhoneIcon from '../../components/svg/commons/phone_ringing'
import SvgMap from '../../components/svg/dealers/maps';
import SvgSite from '../../components/svg/dealers/site';

import { pushToDataLayer } from '../gtm/pushDataLayer'

const StoresList = ({_initialStores, _currentStores, _currentListStores, _listStores, _campaign_name, setCurrentStore, _isCurrentImage}) => {
   
    /* eslint-disable */
    const dealer_image = _initialStores.primary.dealer_image.url
    const [currentDealer, setCurrenDealer] = useState(null)
    const [currentImage, setCurrentImage] = useState(null)
    const [change, setChange] = useState(false)
    const [isInitial, setIsInitial] = useState(true)

    useEffect(() => {

        if(_isCurrentImage){
            setCurrentImage(_listStores.primary.dealer_image.url)
            setIsInitial(false)

           // console.log('dealer', _listStores)
        }
     
    }, [_listStores])

    useEffect(() => {
        if(_currentStores.length > 0 ){
            setChange(true)
            setIsInitial(false)
            let result = []
         
             _currentStores.map((store, index) =>{
                 if(index === 0){
                     setCurrentImage(store.data.dealer.dealer_image.url)
                     result = [store.data.store]
                     setCurrenDealer(result)
                 }
 
             })
         }
    }, [_currentStores ])

    useEffect(() =>{

         if(_currentListStores !== null){
            setChange(true)
            setIsInitial(false)
             let stores = []
            _currentListStores.map(store =>{
                setCurrentImage(store.data.dealer.dealer_image.url)
                stores = [...stores, store.data.store]
                setCurrenDealer(stores)
            })
        }else return
           
    }, [_currentListStores])
  
    function handleClick (e, item) {
        if(item !== null){
            setCurrentStore(item.coordinates)

            const gtmDealerName = item.store
            const gtmStateName = item.state
            const gtmBranchName = item.branchoffice !== null ? (item.branchoffice +"_"+ item.document_id) :  (item.store +"_"+ item.document_id)
            pushToDataLayer({
                event: "deals_selected_branch",
                campaignName: _campaign_name.text,
                dealerName: gtmDealerName,
                stateName: gtmStateName,
                branchName: gtmBranchName,
                eventCallback: () => {},
            })
        }

    }

    function CardList(stores, index) {
      
        return(
            <div className="c-dealers-branchoffices__card" key={index}>
                <div onClick={(e) =>{handleClick(e, stores.data)}}>
                    <h3>{stores.data.store}</h3>
                    <p>{stores.data.address}</p>
                    <p>{stores.data.phone}</p>
                </div>
                <div className="card__links">
                    <Link text="cómo llegar" dealer={stores.data.store}  state={stores.data.state} branch={stores.data.branchoffice} id={stores.data.document_id}
                      url={`http://maps.google.com/maps?&z=15&mrt=yp&t=k&q=
                            ${stores.data.coordinates.latitude},
                            ${stores.data.coordinates.longitude}`}
                     >
                        <SvgMap />
                    </Link>
                    <Link text="Ver sitio" dealer={stores.data.store} state={stores.data.state}  branch={stores.data.branchoffice} id={stores.data.document_id}
                        url={stores.data.website}
                    >
                        <SvgSite />
                    </Link>
                    <Link text="Llamar" dealer={stores.data.store}  state={stores.data.state}  branch={stores.data.branchoffice} id={stores.data.document_id}
                      url={`tel:${stores.data.phone}`}
                    >
                        <PhoneIcon />
                    </Link>
                </div>
            </div>
        )

      
    }

     function Card(dataInicial, currentData ) {
    
      if(currentData !== undefined){
      return  currentData.items.map(resp =>{
        const data = resp.location.document
         return  data.map(item =>{
               if(item.data.state === dataInicial.items.data.state){
                   const response = item.data
                return(
                    <div className="c-dealers-branchoffices__card" key={response.store} >
                         <div onClick={(e) =>{handleClick(e, response)}}>
                            <h3>{response.store}</h3>
                            <p>{response.address}</p>
                            <p>{response.phone}</p>
                        </div>
                       
                        <div className="card__links">
                            <Link text="cómo llegar" dealer={response.store}  state={response.state}  branch={response.branchoffice} id={response.document_id}
                            url={`http://maps.google.com/maps?&z=15&mrt=yp&t=k&q=
                                    ${response.coordinates.latitude},
                                    ${response.coordinates.longitude}`}
                            >
                                <SvgMap />
                            </Link>
                            <Link text="Ver sitio" dealer={response.store}  state={response.state} branch={response.branchoffice} id={response.document_id}
                                url={response.website !== null ? response.website : ""}
                            >
                                <SvgSite />
                            </Link>
                            <Link text="Llamar" dealer={response.store}  state={response.state} branch={response.branchoffice} id={response.document_id}
                            url={`tel:${response.phone}`}
                            >
                                <PhoneIcon />
                            </Link>
                        </div>
                    </div>
                )
               }
            })
           
        })
      }else{
       const dataStores = dataInicial.items.data
            return(
                    <div className="c-dealers-branchoffices__card" key={dataStores.store} >
                        <div onClick={(e) =>{handleClick(e, dataStores)}}>
                            <h3>{dataStores.store}</h3>
                            <p>{dataStores.address}</p>
                            <p>{dataStores.phone}</p>
                        </div>
                       
                        <div className="card__links">
                            <Link text="cómo llegar" dealer={dataStores.store}  state={dataStores.state} branch={dataStores.branchoffice} id={dataStores.document_id}
                            url={`http://maps.google.com/maps?&z=15&mrt=yp&t=k&q=
                                    ${dataStores.coordinates.latitude},
                                    ${dataStores.coordinates.longitude}`}
                            >
                                <SvgMap />
                            </Link>
                            <Link text="Ver sitio" dealer={dataStores.store} state={dataStores.state} branch={dataStores.branchoffice} id={dataStores.document_id}
                                url={dataStores.website}
                            >
                                <SvgSite />
                            </Link>
                            <Link text="Llamar" dealer={dataStores.store} state={dataStores.state} branch={dataStores.branchoffice} id={dataStores.document_id}
                            url={`tel:${dataStores.phone}`}
                            >
                                <PhoneIcon />
                            </Link>
                        </div>
                    </div>
                )
      }
    }

    const handleLink = (e, _name, _dealer, _state, _branch, _id) =>{
        const gtmDealer = _dealer
        const gtmState = _state
        const gtmBranch = _branch !== null ? (_branch +"_"+_id) : (_dealer +"_"+_id)

        let gtmEvent

        if(_name === "cómo llegar"){
            gtmEvent = "deals_selected_branch_geolocation"
        }else if(_name === "Ver sitio"){
            gtmEvent = "deals_selected_branch_website"
        }else if(_name === "Llamar"){
            gtmEvent = "deals_selected_branch_phone"
        }

        pushToDataLayer({
            event: gtmEvent,
            campaignName: _campaign_name.text,
            dealerName: gtmDealer,
            stateName: gtmState,
            branchName: gtmBranch,
            eventCallback: () => {},
        })
    }

    function Link (props) {
        const { text, url, children, dealer, state, branch, id } = props;
    
        return (
            <a href={url} target="_blank" rel="noopener noreferrer" onClick={(e) =>{handleLink(e, text, dealer, state, branch, id)}} >
                {children}
                <span>{text}</span>
            </a>
        )
    }

    return (
        <div className="c-dealers-branchoffices c-stores-list">
            <div className="c-dealers-branchoffices__image">
                <Picture
                className=""
                src={isInitial ? dealer_image : currentImage}
                alt=""
            /> 
            </div>
            {
                  change ? 
                     currentDealer.map((stores, index) => CardList(stores, index)) : Card(_initialStores, _listStores)
            }
        </div>
    )
}

export default StoresList;