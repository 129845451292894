import React from 'react'
import CalorexLogo from '../../components/svg/commons/calorex_logo'

const Storytelling = ({_body}) =>{
    let data = null
    
    _body.map(item =>{
        if(item.__typename === "PrismicSpecialDealsPageBodyStorytelling2"){
          return  data = item.primary
        }else return null
    })

    const title = data.storytelling_title
    const subTitle = data.storytelling_2_description
    return( data && 
           <div className="storytelling_container">
                <div className="container_logo--calorex">
                    <CalorexLogo />
                </div>
                <div className="container_info">
                    <p className="storytelling_title"> {title.text} </p>
                    <p className="storytelling_subtitle"> {subTitle.text} </p>
                </div>
            </div>
    )
}

export default Storytelling;